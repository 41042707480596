<script setup lang="ts">
    import {defineProps} from 'vue';
    import {isValueOther} from '@/services/reporting'
    defineProps({
        rows: {type: Array<any>},
        labels: {type: Array<any>},
    })
    const emits = defineEmits(['next'])
    function isComplex(cell) {
        console.log('reporting:table:cell', cell)
        return typeof cell === 'object'
    }
    function click(event, value) {
        if (isValueOther(value)) {
            event.preventDefault();
            emits('next')
        }
    }
</script>

<template>
    <div class="full-width table-responsive">
        <table class="table" >
            <thead>
            <tr>
                <th v-for="label in labels" :key="label">{{label}}</th>
            </tr>
            </thead>
            <tbody class="">
            <tr v-for="row in rows">
                <td v-for="value in row">
                    <span v-if="isComplex(value)">
                        <a v-if="value.link" :href="value.link" target="_blank" @click="e => click(e, value.label)">{{value.label}}</a>
                        <span v-else @click="e => click(e, value.label)" :class="isValueOther(value.label) ? 'btn btn-link p-0' :'' ">
                            {{value.label}}
                        </span>
                    </span>
                    <span v-else @click="e => click(e, value)" :class="isValueOther(value) ? 'btn btn-link p-0' :'' ">
                        {{value}}
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>

</style>
