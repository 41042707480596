<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {defineProps, computed, ref} from 'vue'
import DashboardTable from "@/components/Controls/charts/DashboardTable.vue";
import {StringUtils} from "@/utilities/Strings";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const rows = computed(() => data.value.map(x => [StringUtils.truncate(x.domain, 100), x.count]));

const {data, load, listen, isLoading, nextPage} = useReportData('top_domains', store, () => props.filters, {limit: 25});

listen();
load().then(() => {
    console.log('report:top_domains:data', data.value.map(x => x.count || 0))
    isShowNoDataMessage.value = data.value.length === 0;
});
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <dashboard-table
        v-else-if="!isLoading && !isShowNoDataMessage"
        :rows="rows"
        @next="nextPage"
        :labels="['Domain', 'Count']"
    ></dashboard-table>
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
